import { Location } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  Signal,
  signal
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ssmm-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss']
})
export class BottomNavComponent {
  @Input() helpPageUrl: string;
  @Input() canSave: boolean;
  @Input() saveButtonText: Signal<string> = signal('Speichern');
  @Input() isSaveButtonDisabled: boolean;
  @Input() canGoBack = true;
  @Input() closeButtonText: Signal<string> = signal('Schließen');
  @Input() goBackToRoot = false;
  @Input() useCloseOutput = false;

  @Output() save: EventEmitter<void> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(private _location: Location, private _router: Router) {}

  navigateToHelpPage(): void {
    const newWindow = window.open(this.helpPageUrl, '_blank');
    newWindow.opener = null;
  }

  goBack(): void {
    if (this.useCloseOutput) {
      this.close.emit(null);
    } else if (this.goBackToRoot) {
      this._router.navigate(['/module']);
    } else {
      this._location.back();
    }
  }

  saveRequested(): void {
    this.save.next(null);
  }
}
